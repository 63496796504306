<template>
    <div class="login-container">
      <div class="login-box">
        <h2>Accedi</h2>
        <form @submit.prevent="login">
          <div class="input-group">
            <input type="text" v-model="username" placeholder="Username" required />
          </div>
          <div class="input-group">
            <input type="password" v-model="password" placeholder="Password" required />
          </div>
          <button type="submit" class="login-button">Login</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { useAuthStore } from '../stores/auth'
  import { useRouter } from 'vue-router'
  import { ref } from 'vue'
  
  export default {
    setup() {
      const authStore = useAuthStore()
      const router = useRouter()
      const username = ref('')
      const password = ref('')
  
      const login = () => {
        if (username.value === process.env.VUE_APP_USERNAME && password.value === process.env.VUE_APP_PASSWORD) {
          authStore.login()
          router.push('/admin')
        } else {
          alert('Credenziali non valide')
        }
      }
  
      return { login, username, password }
    },
  }
  </script>
  
  <style scoped>
  /* Stile della pagina di login */
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f0f2f5;
  }
  
  .login-box {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: center;
    margin-top: -10%;

  }
  
  h2 {
    margin-bottom: 20px;
    color: #333333;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group input {
    width: calc(100% - 85px); /* Larghezza del 100% meno il padding */
    padding: 12px 20px; /* Padding interno */
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    color: #333333;
    transition: border-color 0.3s ease;
    margin: 0 20px; /* Margine laterale per centrare il campo */
  }
  
  .input-group input:focus {
    border-color: #ff7300;
    outline: none;
  }
  
  .login-button {
    width: calc(100% - 40px); /* Larghezza del 100% meno il padding */
    padding: 12px 20px;
    background-color: #ff7300;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 20px; /* Margine laterale per centrare il pulsante */
  }
  
  .login-button:hover {
    background-color: #da6200;
  }
  
  /* Responsive design */
  @media (max-width: 500px) {
    .login-box {
      padding: 20px;
    }
  }
  </style>
  