<template>

  <div class="admin-container">
    
    <div class="logo-container">
      <img src="@/assets/bonaga.png" alt="BonagaTV Logo" class="logo" />
    </div>

    <h1>BonagaTV | Dashboard Admin</h1>

    <div class="event-management">
      <h3>Gestione dell'evento:</h3>
      <select v-model="selectedEvent" @change="onEventChange" class="event-select" :style="{ width: dropdownWidth }">
        <option v-for="event in events" :key="event.id" :value="event.id">
          {{ event.nome }}
        </option>
      </select>
    </div>
    <hr />

    <!-- Sezione ricerca utenze -->
    <h3>Ricerca Utenze</h3>
    <div class="search-section">
      <input
        type="text"
        v-model="searchTerm"
        @input="searchUsers"
        @keyup="handleKeyUp"
        class="search-input"
        placeholder="Cerca utente..."
      />
    </div>
    
    <!-- Spinner di caricamento e overlay -->
    <div v-if="isLoading" class="overlay">
      <div class="spinner"></div>
    </div>

    <!-- Risultati ricerca -->
    <table v-if="users.length" class="user-table">
      <thead>
        <tr>
          <th>Email</th>
          <th>Password</th>
          <th>Data Pagamento Registrazione</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.email }}</td>
          <td>{{ user.password }}</td>
          <td>{{ user.dataDiRegistrazione ? user.dataDiRegistrazione : "Non Disponibile" }}</td>
          <td class="action-buttons">
            <button @click="inoltraCredenziali(user.id)">Inoltra Credenziali</button>
            <button @click="forceLogout(user.email)">Forza Logout</button>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else class="no-results">
      {{ searchTerm.length > 0 ? "Nessun utente trovato" : "Inserisci una mail per ricercare l'utenza associata" }}
    </p>
    <hr />

    <!-- Sezione ultime utenze gratuite -->
    <h3>Gestione Utenze Gratuite</h3>
    <div class="promo-sections">
      <!-- Colonna utenze Bonaga Promo -->
      <div class="promo-column">
        <h4>Bonaga Promo - Ultima inserita</h4>
        <p v-if="lastBonagaPromo">{{ lastBonagaPromo.email }}</p>
        <p v-else>Nessuna utenza inserita</p>
        <button @click="addUser('bonaga')">Aggiungi nuova utenza</button>
      </div>

      <!-- Divider -->
      <div class="vertical-divider"></div>

      <!-- Colonna utenze Segreteria Promo -->
      <div class="promo-column">
        <h4>Segreteria Promo - Ultima inserita</h4>
        <p v-if="lastSegreteriaPromo">{{ lastSegreteriaPromo.email }}</p>
        <p v-else>Nessuna utenza inserita</p>
        <button @click="addUser('segreteria')">Aggiungi nuova utenza</button>
      </div>

      <!-- Divider -->
      <div class="vertical-divider"></div>

      <!-- Colonna utenze Sponsor Promo -->
      <div class="promo-column">
        <h4>Sponsor Promo - Ultima inserita</h4>
        <p v-if="lastSponsorPromo">{{ lastSponsorPromo.email }}</p>
        <p v-else>Nessuna utenza inserita</p>
        <button @click="addUser('sponsor')">Aggiungi nuova utenza</button>
      </div>
    </div>
    <hr />

    <!-- Sezione aggiungi utente -->
    <h3>Aggiungi Nuovo Utente</h3>
    <div class="add-user-section">
      <div class="input-group">
        <input
          type="email"
          v-model="customUserEmail"
          class="email-input"
          placeholder="Inserisci la email"
        />
        <input
          type="password"
          v-model="customUserPassword"
          class="password-input"
          :disabled="!insertPassword"
          placeholder="Inserisci la password"
        />
      </div>
      <div class="password-option">
        <label>
          <input type="checkbox" v-model="insertPassword" />
          Inserisci password
        </label>
      </div>

      <p v-if="successMessage" class="success-message">{{ successMessage }}</p>

      <button @click="addCustomUser" class="add-user-button">Aggiungi Utente</button>
    </div>

    
  </div>
</template>

<script>
import { getUtentiByIdEvento, getUtenzePromo, addPromoUser, addCustomUser, getEvents, inviaCredenziali, forceLogout } from '@/api.js';

export default {
  data() {
    return {
      searchTerm: '',
      users: [],
      lastBonagaPromo: null,
      lastSegreteriaPromo: null,
      lastSponsorPromo: null,
      isLoading: false,
      newUserEmail: '',
      insertPassword: false,
      newUserPassword: '',
      events: [],
      selectedEvent: null, 
      successMessage: '',

    };
  },
  methods: {
    async addCustomUser() {
      const loginData = {
        email: this.customUserEmail,
        password: this.insertPassword ? this.customUserPassword : null
      };

      try {
        this.isLoading = true;
        await addCustomUser(this.selectedEvent, loginData); 
        this.isLoading = false;
        this.customUserEmail = '';
        this.customUserPassword = '';
        this.insertPassword = false; 

        this.successMessage = 'Utente aggiunto con successo!';

        setTimeout(() => {
          this.successMessage = '';
        }, 5000);

      } catch (error) {
        console.error('Errore durante l\'aggiunta dell\'utente:', error);
      }
    },
    async inoltraCredenziali(userId) {
    try {
        this.isLoading = true; // Mostra un indicatore di caricamento, se necessario
        await inviaCredenziali(userId); // Chiamata REST per inoltrare le credenziali
      } catch (error) {
        alert("Errore durante l'inoltro delle credenziali.");
      } finally {
        this.isLoading = false; // Nascondi l'indicatore di caricamento
      }
    },
    async forceLogout(email) {
    try {
        this.isLoading = true; // Mostra un indicatore di caricamento, se necessario
        await forceLogout(email); // Chiamata REST per inoltrare le credenziali
      } catch (error) {
        alert("Errore durante l'inoltro delle credenziali.");
      } finally {
        this.isLoading = false; // Nascondi l'indicatore di caricamento
      }
    },
    async searchUsers() {
      // Controlla se il campo di input è vuoto dopo il cambiamento
      if (!this.searchTerm) {
        this.users = []; // Svuota l'array utenti
        return; // Esci dalla funzione se non ci sono caratteri
      }

      // Esegui la ricerca solo se ci sono caratteri nel campo di input
      if (this.searchTerm.length > 0) {
        try {
          this.users = await getUtentiByIdEvento(this.selectedEvent, this.searchTerm);
        } catch (error) {
          console.error('Errore nella ricerca:', error);
          this.users = []; // Svuota l'array utenti in caso di errore
        }
      }
      
    },
    handleKeyUp(event) {
      // Verifica se il tasto premuto è il tasto Canc (keyCode 46)
      if (event.key === 'Backspace' || event.key === 'Delete') {
        // Se il campo è vuoto, svuota l'array utenti
        if (this.searchTerm.trim() === '') {
          this.users = []; // Svuota l'array utenti
        }
      }
    },
    async loadPromoUsers() {
      this.isLoading = true;
      try {
        const promoData = await getUtenzePromo(this.selectedEvent);
        this.lastBonagaPromo = promoData.utenzeBonagaPromo?.slice(-1)[0] || null;
        this.lastSegreteriaPromo = promoData.utenzeSegreteria?.slice(-1)[0] || null;
        this.lastSponsorPromo = promoData.utenzeSponsor?.slice(-1)[0] || null;
      } catch (error) {
        console.error('Errore durante il caricamento delle utenze gratuite:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async loadEvents() {
      this.isLoading = true;
      try {
        const response = await getEvents(); // Recupera gli eventi
        this.events = response; // Assicurati che la risposta sia un array di eventi

        // Calcola la lunghezza massima del nome evento
        const maxLength = this.events.reduce((max, event) => {
          return Math.max(max, event.nome.length);
        }, 0);
        
        // Imposta la larghezza del menu a tendina in base alla lunghezza del nome più lungo
        this.dropdownWidth = `${maxLength * 8 + 50}px`; // Moltiplica per 10 per avere una stima della larghezza in pixel

        if (this.events.length > 0) {
          this.selectedEvent = this.events[this.events.length - 1].id; // Imposta di default l'ultimo evento
        }
      } catch (error) {
        console.error('Errore durante il caricamento degli eventi:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async addUser(type) {
      try {
        this.isLoading = true
        const nuovaMail = await addPromoUser(this.selectedEvent, type.toUpperCase());

        if (type === 'bonaga') {
          this.lastBonagaPromo = nuovaMail;
        } else if (type === 'segreteria') {
          this.lastSegreteriaPromo = nuovaMail;
        } else if (type === 'sponsor') {
          this.lastSponsorPromo = nuovaMail;
        }
      } catch (error) {
        console.error('Errore durante l\'aggiunta di una nuova utenza:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async initializeData() {
      await this.loadEvents(); // Attende il caricamento degli eventi
      if (this.selectedEvent) { // Verifica che selectedEvent sia stato popolato
        await this.loadPromoUsers(); // Carica le utenze promozionali solo se selectedEvent è valido
      }
    }
  },
  mounted() {
    this.initializeData();
  }
};
</script>

<style>
.user-table button {
  padding: 6px 12px;
  background-color: #ff7300;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 5px;
}

.user-table button:hover {
  background-color: #da6200;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 8px; /* Aggiunge spazio tra i pulsanti */
}

.success-message {
  color: green;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.event-management {
  display: flex; /* Usa flexbox per allineare gli elementi */
  align-items: center; /* Allinea verticalmente al centro */
  justify-content: center; /* Centra orizzontalmente */
}

.event-management h3 {
  margin: 0; /* Rimuove il margine predefinito dell'elemento h3 */
  margin-right: 10px; /* Aggiunge spazio tra il titolo e il menu a tendina */
}

.event-select {
  width: 200px; /* Imposta una larghezza fissa per il menu a tendina */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.add-user-section {
  margin-bottom: 20px;
  text-align: center;
  padding: 20px; /* Padding uniforme */
  background: #ffffff; /* Sfondo bianco */
  border-radius: 8px; /* Raggio del bordo */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Ombra */
}

.input-group {
  display: flex; /* Usa Flexbox per allineare gli input sulla stessa riga */
  justify-content: center; /* Centra gli input orizzontalmente */
  align-items: center; /* Centra gli input verticalmente */
  margin-bottom: 10px; /* Margine tra il gruppo di input e il bottone */
}

.email-input, .password-input {
  width: 40%; /* Larghezza del 40% per entrambi gli input */
  padding: 10px; /* Padding uniforme */
  margin: 0 10px; /* Margini orizzontali per separare gli input */
  border: 1px solid #ddd; /* Stessa bordatura */
  border-radius: 8px; /* Raggio del bordo coerente */
}

.password-option {
  margin-top: 10px; /* Margine superiore per separare dal gruppo di input */
  text-align: left; /* Allinea il testo a sinistra */
  margin-left: 51%;
  margin-bottom: 20px;
}

.add-user-button {
  padding: 10px 20px; /* Padding simile agli altri bottoni */
  border: none; /* Nessun bordo */
  border-radius: 6px; /* Raggio del bordo coerente */
  background: #ff7300; /* Colore di sfondo */
  color: #fff; /* Colore del testo */
  font-weight: 600; /* Stessa pesantezza del font */
  cursor: pointer; /* Indica che è cliccabile */
  transition: background 0.3s; /* Transizione per l'effetto hover */
}

.add-user-button:hover {
  background: #da6200; /* Colore hover coerente */
}

.overlay {
  position: fixed; /* Posiziona l'overlay rispetto alla finestra */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Sfondo scuro e trasparente */
  display: flex;
  justify-content: center; /* Centra il contenuto orizzontalmente */
  align-items: center; /* Centra il contenuto verticalmente */
  z-index: 1000; /* Assicura che l'overlay sia sopra agli altri contenuti */
}

.spinner {
  border: 4px solid #f3f3f3; /* Colore dello sfondo */
  border-top: 4px solid #3498db; /* Colore della parte superiore */
  border-radius: 50%;
  width: 60px; /* Dimensione dello spinner */
  height: 60px; /* Dimensione dello spinner */
  animation: spin 1s linear infinite; /* Animazione dello spinner */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.logo {
  max-width: 150px;
  height: auto;
}

.admin-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 40px;
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

h1 {
  margin-bottom: 20px;
  color: #444;
  text-align: center;
}

.search-section {
  text-align: center;
  margin-bottom: 20px;
}

.search-input {
  width: 60%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.user-table th, .user-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #f1f1f1;
}

.user-table th {
  background: #eaeaea;
  color: #555;
  font-weight: 600;
}

.no-results {
  text-align: center;
  color: #888;
  font-size: 14px;
}

.promo-sections {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.promo-column {
  flex: 1;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  text-align: center;
  margin-bottom: 10px;
}

.promo-column h4 {
  margin-bottom: 12px;
  color: #444;
  font-size: 16px;
}

.promo-column p {
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
}

.promo-column button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #ff7300;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s;
}

.promo-column button:hover {
  background: #da6200;
}

.vertical-divider {
  width: 2px;
  background-color: #ddd;
  margin: 0 15px;
}
</style>
