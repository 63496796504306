const BASE_URL = process.env.VUE_APP_API_BASE_URL;

const getAuthCredentials = () => {
    const username = process.env.VUE_APP_API_USERNAME;
    const password = process.env.VUE_APP_API_PASSWORD; 
    return btoa(`${username}:${password}`); 
  };

export const getUtentiByIdEvento = async (idEvento, emailDigitata) => {
    try {
        const response = await fetch(`${BASE_URL}/admin/getAllUsers/${idEvento}?email=${emailDigitata}`, {
          method: 'GET',
          headers: {
            'Authorization': `Basic ${getAuthCredentials()}`,
            'Content-Type': 'application/json',
          }
        });
    
        if (!response.ok) {
            throw new Error('Errore nella richiesta');
          }
          
          const data = await response.json();
          return data.map(user => ({
            id: user.id,
            email: user.email,
            password: atob(user.password),
            dataDiRegistrazione: user.dataDiRegistrazione,
          }));
      } catch (error) {
        console.error('Errore durante la ricerca degli utenti:', error);
        throw error;
      }
};

export const getUtenzePromo = async (idEvento) => {
  try {
    const response = await fetch(`${BASE_URL}/admin/getUtenzeGratuite/${idEvento}`, {
      method: 'GET',
      headers: {
        'Authorization': `Basic ${getAuthCredentials()}`,
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
        throw new Error('Errore nella richiesta');
      }
      
      const data = await response.json();
      return data;

  } catch (error) {
    console.error('Errore durante la ricerca degli utenti:', error);
    throw error;
  }
};

export const addPromoUser = async (idEvento, utenzaType) => {
  try {
      const response = await fetch(`${BASE_URL}/admin/addPromoUser/${idEvento}?utenza=${utenzaType}`, {
          method: 'POST',
          headers: {
              'Authorization': `Basic ${getAuthCredentials()}`,
              'Content-Type': 'application/json',
          },
      });
      
      if (!response.ok) {
          throw new Error('Errore nella richiesta di aggiunta utenza promozionale');
      }

      const data = await response.json();
      return data; 
  } catch (error) {
      console.error('Errore durante l\'aggiunta di una nuova utenza promozionale:', error);
      throw error;
  }
};

export const addCustomUser = async (idEvento, loginData) => {

  try {
      const response = await fetch(`${BASE_URL}/admin/add-utente/?evento=${idEvento}`, {
          method: 'POST',
          headers: {
              'Authorization': `Basic ${getAuthCredentials()}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginData)
      });
      
      if (!response.ok) {
          throw new Error('Errore nella richiesta di aggiunta utenza promozionale');
      }

  } catch (error) {
      console.error('Errore durante l\'aggiunta di una nuova utenza promozionale:', error);
      throw error;
  }

};

export const getEvents = async () => {

  try {
    const response = await fetch(`${BASE_URL}/admin/getPPVEvents`, {
        method: 'GET',
        headers: {
            'Authorization': `Basic ${getAuthCredentials()}`,
            'Content-Type': 'application/json',
        }
    });
    
    if (!response.ok) {
        throw new Error('Errore nella richiesta di aggiunta utenza promozionale');
    }

    const data = await response.json();
    return data;

  } catch (error) {
      console.error('Errore durante l\'aggiunta di una nuova utenza promozionale:', error);
      throw error;
  }

};

export const forceLogout = async (email) => {

  try {
    const response = await fetch(`${BASE_URL}/admin/force-logout?email=${email}`, {
        method: 'POST',
        headers: {
            'Authorization': `Basic ${getAuthCredentials()}`,
            'Content-Type': 'application/json',
        }
    });
    
    if (!response.ok) {
        throw new Error('Errore nella richiesta di aggiunta utenza promozionale');
    }

    return

  } catch (error) {
      console.error('Errore durante l\'aggiunta di una nuova utenza promozionale:', error);
      throw error;
  }


};

export const inviaCredenziali = async (idUtente) => {

  try {
    const response = await fetch(`${BASE_URL}/utente/send-email?id=${idUtente}&lang=it`, {
        method: 'POST',
        headers: {
            'Authorization': `Basic ${getAuthCredentials()}`,
            'Content-Type': 'application/json',
        }
    });
    
    if (!response.ok) {
        throw new Error('Errore nella richiesta di aggiunta utenza promozionale');
    }

    return

  } catch (error) {
      console.error('Errore durante l\'aggiunta di una nuova utenza promozionale:', error);
      throw error;
  }

};
